//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppTabs from "@/components/AppTabs.vue";
import { FadeTransition } from "vue2-transitions";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import CellItem from "@/components/Cell/Item.vue";
import CellItemMulti from "@/components/Cell/ItemMulti.vue";
import CellList from "@/components/Cell/List.vue";
import LargeChart from "@/components/charts/LargeChart.vue";
import LoadingOverlay from "@/components/Loading/Overlay";
export default {
  components: {
    AppTabs,
    FadeTransition,
    AppSelectMulti,
    CellItem,
    CellItemMulti,
    CellList,
    LargeChart,
    LoadingOverlay
  },
  props: {
    cells: {
      type: Object,
      required: true
    },
    loader: {
      type: Function,
      required: true
    },
    loaderConfig: {
      type: Object,
      required: true
    },
    period: {
      type: Array,
      required: true
    },
    noDynamic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabs_charts: [{
        id: 1,
        title: "期间汇报"
      }, {
        id: 2,
        title: "指标动态"
      }],
      tabs_charts_active: null,
      select_chart_model: this.cells[Object.keys(this.cells)[0]].multi ? [this.cells[Object.keys(this.cells)[1]]] : [this.cells[Object.keys(this.cells)[0]]],
      loaded: null,
      loading: false,
      error: false
    };
  },
  computed: {
    select_chart_items() {
      const result = {};
      Object.keys(this.cells).forEach(key => {
        if (!this.cells[key].multi) {
          result[key] = this.cells[key];
        }
      });
      return result;
    },
    chartDatasets() {
      return this.select_chart_model.map(selectItem => {
        const cell = this.cells[selectItem.id];
        return {
          color: cell.color,
          data: this.getLoaded(selectItem.id).dynamic,
          unit: cell.unit,
          title: cell.title
        };
      });
    }
  },
  methods: {
    onCellClick(type) {
      if (!this.noDynamic) {
        this.tabs_charts_active = this.tabs_charts[1];
        this.select_chart_model = [this.cells[type]];
      }
    },
    getLoaded(type) {
      if (!this.loaded || !this.loaded[type]) {
        return {
          total: null,
          dynamic: []
        };
      }
      return this.loaded[type];
    },
    getSign(key) {
      const cell = this.cells[key];
      if (cell.percentOf) {
        const v = this.getLoaded(key).total || 0;
        const base = this.getLoaded(cell.percentOf).total;
        const percent = base ? Number(v / base * 100).toFixed(0) : null;
        return percent ? `(${percent}%)` : null;
      }
      return cell.unit;
    },
    getMulti(type) {
      return this.cells[type].multi.map(cell => {
        return {
          title: cell.title,
          info: this.getLoaded(cell.id).total,
          infoMin: cell.unit
        };
      });
    }
  },
  watch: {
    loaderConfig: {
      immediate: true,
      async handler(v, old) {
        if (JSON.stringify(v) === JSON.stringify(old)) {
          return;
        }
        this.loading = true;
        this.error = false;
        try {
          this.loaded = await this.loader();
        } catch (e) {
          this.loading = false;
          this.error = true;
          throw e;
        }
        this.loading = false;
      }
    },
    cells() {
      this.select_chart_model = [this.cells[Object.keys(this.cells)[0]]];
    }
  }
};